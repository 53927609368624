<template>
  <FormGroupMultiselectPaginate
    class="offer-switch-dropdown"
    store="offers"
    track-by="id"
    option-label="title"
    option-image="logo"
    :on-select="handleOnSelect"
    :hide-selected="true"
    :placeholder="$gettext('Search offer...')"
    :value="offerSwitchDropdownSelectedItem"
    @fetched="handleFetched"
    :disabled="disabled"
  />
</template>
<script>
import FormGroupMultiselectPaginate from "@/components/FormGroups/FormGroupMultiselectPaginate";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      disabled: false
    };
  },
  components: { FormGroupMultiselectPaginate },
  computed: {
    ...mapGetters("offers", ["offerSwitchDropdownSelectedItem"])
  },
  methods: {
    handleOnSelect(value) {
      if (this.$route.name === "application-candidate") {
        this.$router.push({
          name: "offer-applications",
          params: { offerId: value.id }
        });
      } else {
        this.$router.replace({
          params: { offerId: value.id }
        });
      }
    },
    handleFetched(data) {
      if (data.total <= 1 && data.search.length === 0) {
        this.disabled = true;
      }
    }
  }
};
</script>

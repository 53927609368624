<template>
  <div class="row">
    <div class="col-md-12">
      <div
        class="d-flex justify-content-between align-items-center mb-4 mb-md-0"
      >
        <OfferSwitchDropdown />
        <router-link
          class="btn btn-primary"
          :to="{
            name: 'update-offer',
            params: { offerId }
          }"
          ><translate>Modify offer</translate></router-link
        >
      </div>

      <div class="nav-tabs-navigation">
        <div class="nav-tabs-wrapper">
          <b-nav align="left">
            <b-nav-item :to="{ name: 'offer-details' }"
              ><i class="fas fa-file-alt mr-2"></i>
              <translate>Details</translate></b-nav-item
            >
            <b-nav-item :to="{ name: 'application-candidate' }"
              ><i class="fas fa-users mr-2"></i>
              <translate>Applications</translate>
              <span class="badge badge-danger" v-if="newApplicationCount">
                {{ newApplicationCount }}
              </span>
            </b-nav-item>
            <b-nav-item :to="{ name: 'publications' }"
              ><i class="fas fa-bullhorn mr-2"></i
              ><translate>Posting platforms</translate></b-nav-item
            >
          </b-nav>
        </div>
      </div>

      <content-loader v-if="showLoader('offer-tab')" />
      <router-view v-show="!showLoader('offer-tab')" :offerId="offerId" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import OfferSwitchDropdown from "@/components/Offers/OfferSwitchDropdown";
import ContentLoader from "@/layouts/components/ContentLoader";

export default {
  components: { OfferSwitchDropdown, ContentLoader },
  props: {
    offerId: {
      type: [Number, String],
      required: true
    }
  },
  created() {
    this.startFetching();
    this.fetchData(this.offerId).finally(this.stopFetching);
  },
  computed: {
    ...mapGetters("offers", {
      offer: "item",
      newApplicationCount: "newApplicationCount"
    }),
    ...mapGetters("app", ["isFetching", "showLoader"])
  },
  methods: {
    ...mapActions("offers", ["fetchOne", "fetchStats"]),
    ...mapActions("app", ["stopFetching", "startFetching"]),
    ...mapMutations("app", ["startLoader", "stopLoader"]),
    fetchData(offerId) {
      return Promise.all([this.fetchOne(offerId), this.fetchStats(offerId)]);
    }
  },
  watch: {
    "$route.params": {
      async handler(current, previous) {
        if (parseInt(current.offerId) !== parseInt(previous.offerId)) {
          this.startLoader("offer-tab");
          this.fetchData(current.offerId).finally(() =>
            this.stopLoader("offer-tab")
          );
        }
      }
    }
  }
};
</script>
